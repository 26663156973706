import Vue from 'vue'
import { ToastPlugin, ModalPlugin, VBToggle } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import axios from 'src/libs/axios.js'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import vueConfig from 'vue.config'
import VueGoodTablePlugin from 'vue-good-table'
// import the styles
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'

import _ from 'lodash'
import App from './App.vue'
import store from './store'
import router from './router'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css"
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
Vue.use(Antd);
Vue.use(Toast);

Vue.set(Vue.prototype, '$_', _)
// Note: Vue automatically prefixes the directive name with 'v-'
Vue.directive('b-toggle', VBToggle)
// @ts-ignore
Vue.component('v-select', vSelect)
Vue.use(VueGoodTablePlugin)
// import VueCookies from 'vue-cookies'
 import { abilitiesPlugin } from '@casl/vue'
import ability from './libs/acl/ability'

Vue.use(abilitiesPlugin, ability)
// Vue.use(VueCookies)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')
require('@core/scss/vue/libs/vue-select.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  //vuetify,
  render: h => h(App),
}).$mount('#app')
