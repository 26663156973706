export default {
  // Endpoints
  loginEndpoint: 'https://login.microsoftonline.com/medloopus.com/oauth2/v2.0/authorize?client_id=019db20d-1c27-4dff-8079-3d6a6a90f0d8&scope=user.read openid profile offline_access&redirect_uri=http://localhost:3000/redirect&response_mode=query&response_type=code',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
