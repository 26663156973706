import Vue from 'vue'
import router from '@/router'
// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})
export const connection = process.env.NODE_ENV === 'development'
  ? 'https://localhost:44316'
  : 'https://api.medloopus.com'
axios.defaults.baseURL = connection
axios.defaults.withCredentials = true
axios.interceptors.response.use(response => response, error => {
  if (error.response.status === 401) {
    localStorage.clear()
    router.replace('/login')
  }
  return error
})
Vue.prototype.$http = axios

export default axios
