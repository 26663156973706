import ability from './ability'
import {AbilityBuilder, Ability } from '@casl/ability'
import { initialAbility } from './config'
export const canNavigate = to => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  
const { can, cannot, rules } = new AbilityBuilder(Ability);


if(userData&&userData.role==='User'){
  console.log('isUser')
    can('read', 'Auth');
    can('read','User')
}
else if(userData&&userData.role==='Admin'){
  console.log('isAdmin')
    can('read', 'Auth');
    can('read','User')
    can('read','Admin')
}
else{
    can('read', 'Auth');
}
ability.update(rules)
  console.log(to)
  console.log(ability.can('read', 'User'))
  console.log(ability.cannot("read","Admin"))
  console.log(to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource)))

  return to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))
}
export const can = (action , resource)=>{
  const userData = JSON.parse(localStorage.getItem('userData'))
  
const { can, cannot, rules } = new AbilityBuilder(Ability);


if(userData&&userData.role==='User'){
  console.log('isUser')
    can('read', 'Auth');
    can('read','User')
}
else if(userData&&userData.role==='Admin'){
  console.log('isAdmin')
    can('read', 'Auth');
    can('read','User')
    can('read','Admin')
}
else{
    can('read', 'Auth');
}
ability.update(rules)
return ability.can(action || 'read', resource)
}
export const _ = undefined
