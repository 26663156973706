import {AbilityBuilder, Ability } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem('userData'))
const existingAbility = userData ? userData.ability : null
const { can, cannot, rules } = new AbilityBuilder(Ability);

if(userData&&userData.role==='User'){
    console.log('isUser')
    can('read', 'Auth');
    can('read','User')
    can('read','Admin')
}
else if(userData&&userData.role==='Admin'){
    console.log('isAdmin')
    can('read', 'Auth');
    can('read','User')
    can('read','Admin')
}
else{
    can('read', 'Auth');
}


export default new Ability(rules)
